import React from 'react'
import { useRef, useState, useEffect } from 'react'
import store from '../../store'

import Activity from './Activity'
import Party from './Party'
import Header from './Header'
import PanelMenu from './PanelMenu'
import PanelContent from './PanelContent'

import './Base.css'
import './Game.css'

const Game = () => {
  const [width, setWidth] = useState(482)
  const myRef = useRef(null);

  useEffect(() => {
    fancyLog();
  }, []);

  function fancyLog() {
    console.log("%c Rendered with 🎁", "background: purple; color: #fff")
    console.log(store.getState())
  }

  function handleDragStart(e) {

  }

  function handleDrag(e) {
    updateWidth(e)
  }

  function handleDragEnd(e) {
    updateWidth(e)
  }

  function updateWidth(e) {
    let xPosition = e.clientX
    let windowWidth= window.innerWidth
    setWidth(windowWidth - xPosition)
  }

  return(
    <div className='Game'>
      <div className='Game__header'>
        <Header />
      </div>
      <div className='Game__layout'>
        <div className='Game__activity'>
          <Activity />
        </div>
        <div className='Game__resizer'
          draggable={true}
          onDragEnd={handleDragEnd}
          onDrag={handleDrag}
          onDragStart={handleDragStart}
        ></div>
        <div className='Game__panel' ref={myRef} style={{flex: `0 0 ${width}px`}}>
          <div className='Game__panelMenu'>
            <PanelMenu />
          </div>
          <div className='Game__panelContent'>
            <PanelContent />
          </div>
        </div>
      </div>

      <div className='Game__party'>
        <Party />
      </div>
    </div>
  )
}

export default Game
