import React from 'react'
import { connect } from 'react-redux'
import SettingsIcon from '@material-ui/icons/Settings'
import FeedbackIcon from '@material-ui/icons/Feedback'
import NewReleasesIcon from '@material-ui/icons/NewReleases';

import './Header.css'

import { openSettings, openFeedback, openReleaseNotes } from '../../actions'
import store from '../../store'
import Settings from './Settings'
import Feedback from './Feedback'
import ReleaseNotes from './ReleaseNotes'

const ONE_WEEK_IN_MILLIS = 1615683600

const Header = (props) => {
  const {
    characterId,
    characters,
    feedbackOpen,
    name,
    settingsOpen,
    releaseNotesOpen,
    releaseNotes,
  } = props

  const character = characters[characterId]

  const handleSettingsIconClick = () => {
    store.dispatch(openSettings())
  }

  const handleFeedbackIconClick = () => {
    store.dispatch(openFeedback())
  }

  const handleReleaseNotesIconClick = () => {
    store.dispatch(openReleaseNotes())
  }

  if (character === undefined) {
    window.location.replace('/games')
    return <span />
  }

  const releaseNoteKeys = Object.keys(releaseNotes)
  const latestRelease = Date.parse(releaseNoteKeys[0])
  const now = Date.parse(new Date())

  let NewReleasesIconColor = '#a0a0a0'

  if (now - latestRelease < ONE_WEEK_IN_MILLIS) {
    NewReleasesIconColor = '#ff7045'
  }

  return (
    <div className='Header'>
      {settingsOpen ? <Settings characterName={character.name} /> : null}
      {feedbackOpen ? <Feedback /> : null}
      {releaseNotesOpen ? <ReleaseNotes /> : null}
      <div className='Header__title lets-roll-text-xl'>
        {name} (Playing as {character.name})
      </div>
      <div className='Header__controls'>
        <div className='Header__control'>
          <NewReleasesIcon
            fontSize="large"
            style={{ color: NewReleasesIconColor, cursor: 'pointer' }}
            onClick={handleReleaseNotesIconClick}
          />
          <p>What's<br />New!</p>
        </div>
        <div className='Header__control'>
          <SettingsIcon
            fontSize="large"
            style={{ color: '#a0a0a0', cursor: 'pointer' }}
            onClick={handleSettingsIconClick}
          />
          <p>Settings</p>
        </div>
        <div className='Header__control'>
          <FeedbackIcon
            fontSize="large"
            style={{ color: '#2896d8', cursor: 'pointer' }}
            onClick={handleFeedbackIconClick}
            title='Send Feedback!'
          />
          <p>Send<br />Feedback!</p>
        </div>
    </div>
    </div>
  )
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    name: state.game.name,
    characters: state.characters.list,
    characterId: state.characterId,
    settingsOpen: state.client.settingsOpen,
    feedbackOpen: state.client.feedbackOpen,
    releaseNotesOpen: state.client.releaseNotesOpen,
    ownerId: state.ownerId,
    releaseNotes: state.client.releaseNotes
  }
}

const mapDispatchToProps = {  }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
