import store from '../store'

/* Avatar */
import {
  ADD_AVATAR,
  CLOSE_ADD_AVATAR,
  DESTROY_AVATAR,
  OPEN_ADD_AVATAR,
} from '../constants/action-types'

export const addAvatar = avatarData => ({
  type: ADD_AVATAR,
  payload: avatarData,
  characterId: store.getState().characterId,
})

export const destroyAvatar = avatarData => ({
  type: DESTROY_AVATAR,
  payload: avatarData
})

export const openAddAvatar = () => ({
  type: OPEN_ADD_AVATAR,
  payload: true
})

export const closeAddAvatar = () => ({
  type: CLOSE_ADD_AVATAR,
  payload: false
})


/* Character */
import {
  ADD_CHARACTER,
  DESTROY_CHARACTER,
  UPDATE_CHARACTER,
  UPDATE_NEW_CHARACTER_AVATAR_URL,
  UPDATE_NEW_CHARACTER_NAME,
} from '../constants/action-types'

export const addCharacter = characterData => ({
  type: ADD_CHARACTER,
  payload: characterData
})

export const destroyCharacter = characterData => ({
  type: DESTROY_CHARACTER,
  payload: characterData
})

export const updateCharacter = characterData => ({
  type: UPDATE_CHARACTER,
  payload: characterData
})

export const reorderCharacters = orderedCharacterIds => ({
  type: REORDER_CHARACTERS,
  payload: orderedCharacterIds,
})

export const updateNewCharacterName = newCharacterName => ({
  type: UPDATE_NEW_CHARACTER_NAME,
  payload: {
    newCharacterName: newCharacterName
  }
})

export const updateNewCharacterAvatarUrl = newCharacterAvatarUrl => ({
  type: UPDATE_NEW_CHARACTER_AVATAR_URL,
  payload: {
    newCharacterAvatarUrl: newCharacterAvatarUrl
  }
})


/* Feedback */
import {
  CLOSE_FEEDBACK,
  OPEN_FEEDBACK,
} from '../constants/action-types'

export const openFeedback = () => ({
  type: OPEN_FEEDBACK,
  payload: true
})

export const closeFeedback = () => ({
  type: CLOSE_FEEDBACK,
  payload: false
})


/* Game */
import {
  REORDER_CHARACTERS,
  UPDATE_GAME,
} from '../constants/action-types'

export const updateGame = (gameData) => ({
  type: UPDATE_GAME,
  payload: gameData,
})

export const reorderGame = (gameData) => {
  return {
    type: REORDER_CHARACTERS,
    payload: gameData.ordered_ids,
  }
}


/* Group Rolls */
import {
  ADD_GROUP_ROLL,
  DESTROY_GROUP_ROLL,
  UPDATE_GROUP_ROLL,
  UPDATE_GROUP_ROLL_NAME,
  UPDATE_GROUP_ROLL_SHORTCUT_COMMAND,
} from '../constants/action-types'

export const addGroupRoll = (groupRollData) => ({
  type: ADD_GROUP_ROLL,
  payload: groupRollData
})

export const updateGroupRoll = (groupRollData) => ({
  type: UPDATE_GROUP_ROLL,
  payload: groupRollData
})

export const destroyGroupRoll = (groupRollData) => ({
  type: DESTROY_GROUP_ROLL,
  payload: groupRollData
})

export const updateGroupRollName = (groupRollName) => ({
  type: UPDATE_GROUP_ROLL_NAME,
  payload: groupRollName
})

export const updateGroupRollShortcutCommand = (groupRollShortcutCommand) => ({
  type: UPDATE_GROUP_ROLL_SHORTCUT_COMMAND,
  payload: groupRollShortcutCommand
})


/* Invitation */
import {
  ADD_INVITATION,
  DESTROY_INVITATION,
  UPDATE_NEW_INVITATION,
} from '../constants/action-types'

export const updateNewInvitation = (newInvitation) => ({
  type: UPDATE_NEW_INVITATION,
  payload: newInvitation,
})

export const addInvitation = (invitationData) => ({
  type: ADD_INVITATION,
  payload: invitationData,
})

export const destroyInvitation = (invitationData) => ({
  type: DESTROY_INVITATION,
  payload: invitationData,
})


/* Monsters */
import {
  CHOOSE_MONSTER,
  CLEAR_MONSTER_SEARCH,
  REMOVE_MONSTER,
  FETCH_MONSTERS_BEGIN,
  FETCH_MONSTERS_FAILURE,
  FETCH_MONSTERS_SUCCESS,
  FETCH_MONSTER_BEGIN,
  FETCH_MONSTER_FAILURE,
  FETCH_MONSTER_SUCCESS,
  UPDATE_MONSTER_SEARCH,
} from '../constants/action-types'

export async function fetchMonsters(dispatch, getState) {
  dispatch(fetchMonstersBegin())

  const baseUrl = `${window.location.protocol}//${location.host}/monsters/by_id`
  const response = await fetch(baseUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': getState().client.token
    }
  })
    .then(response => response.json())
    .then(data => dispatch(fetchMonstersSuccess(data)))
    .catch((error) => dispatch(fetchMonstersFailure(error)))
}

export const fetchMonstersBegin = () => ({
  type: FETCH_MONSTERS_BEGIN
})

export const fetchMonstersSuccess = (monsterData) => ({
  type: FETCH_MONSTERS_SUCCESS,
  payload: monsterData
})

export const fetchMonstersFailure = (error) => ({
  type: FETCH_MONSTERS_FAILURE,
  payload: { error }
})

export const updateMonsterSearch = (query) => ({
  type: UPDATE_MONSTER_SEARCH,
  payload: query,
})

export const chooseMonster = (monsterId) => ({
  type: CHOOSE_MONSTER,
  payload: monsterId,
})

export const clearMonsterSearch = () => ({
  type: CHOOSE_MONSTER
})

export const removeMonster = (monsterId) => ({
  type: REMOVE_MONSTER,
  payload: monsterId,
})

export const fetchMonsterBegin = () => ({
  type: FETCH_MONSTER_BEGIN,
})

export const fetchMonsterSuccess = (monsterData) => ({
  type: FETCH_MONSTER_SUCCESS,
  payload: monsterData
})

export const fetchMonsterFailure = (error) => ({
  type: FETCH_MONSTER_FAILURE,
  payload: error
})

export const loadMonster = (monsterData) => {
  return async function(dispatch, getState) {
    dispatch(fetchMonsterBegin())

    fetch(`/${monsterData.path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': getState().client.token
      }
    })
      .then(response => response.json())
      .then(data => dispatch(fetchMonsterSuccess(data)))
      .catch((error) => dispatch(fetchMonsterFailure(error)))
  }
}


/* Release Notes */
import {
  CLOSE_RELEASE_NOTES,
  OPEN_RELEASE_NOTES,
} from '../constants/action-types'

export const openReleaseNotes = () => ({
  type: OPEN_RELEASE_NOTES,
  payload: true
})

export const closeReleaseNotes = () => ({
  type: CLOSE_RELEASE_NOTES,
  payload: false
})


/* Registry */
import {
  ADD_REGISTRY,
  DESTROY_REGISTRY,
  UPDATE_REGISTRY,
} from '../constants/action-types'

export const addRegistry = (registryData) => ({
  type: ADD_REGISTRY,
  payload: registryData,
  characterId: store.getState().characterId,
})

export const destroyRegistry = (registryData) => ({
  type: DESTROY_REGISTRY,
  payload: registryData
})

export const updateRegistry = (registryData) => ({
  type: UPDATE_REGISTRY,
  payload: registryData,
  characterId: store.getState().characterId,
})


/* Rolls */
import {
  ADD_ROLL,
  UPDATE_ROLL_COMMAND,
} from '../constants/action-types'

export const addRoll = rollData => ({
  type: ADD_ROLL,
  payload: {
    ...rollData,
    ownerId: store.getState().ownerId,
    characterId: store.getState().characterId,
  }
})

export const updateRollCommand = updatedRollCommand => ({
  type: UPDATE_ROLL_COMMAND,
  payload: {
    rollCommand: updatedRollCommand
  }
})


/* Settings */
import {
  CLOSE_SETTINGS,
  OPEN_SETTINGS,
} from '../constants/action-types'

export const openSettings = () => ({
  type: OPEN_SETTINGS,
  payload: true
})

export const closeSettings = () => ({
  type: CLOSE_SETTINGS,
  payload: false
})


/* Shortcut */
import {
  ADD_SHORTCUT,
  DESTROY_SHORTCUT,
  UPDATE_SHORTCUT,
  UPDATE_NEW_SHORTCUT_COMMAND,
  UPDATE_NEW_SHORTCUT_LABEL,
  SET_SHORTCUT_SORT_ORDER,
  OPEN_EDIT_SHORTCUT,
  CLOSE_EDIT_SHORTCUT,
} from '../constants/action-types'

export const addShortcut = (shortcutData) => ({
  type: ADD_SHORTCUT,
  payload: {
    ...shortcutData,
    currentCharacterId: store.getState().characterId,
  }
})

export const destroyShortcut = (shortcutData) => ({
  type: DESTROY_SHORTCUT,
  payload: shortcutData
})

export const updateShortcut = (shortcutData) => ({
  type: UPDATE_SHORTCUT,
  payload: {
    ...shortcutData,
    currentCharacterId: store.getState().characterId,
  }
})

export const updateNewShortcutLabel = newShortcutLabel => ({
  type: UPDATE_NEW_SHORTCUT_LABEL,
  payload: {
    newShortcutLabel: newShortcutLabel
  }
})

export const updateNewShortcutCommand = newShortcutCommand => ({
  type: UPDATE_NEW_SHORTCUT_COMMAND,
  payload: {
    newShortcutCommand: newShortcutCommand
  }
})

export const setShortcutSortOrder = newSortOrder => ({
  type: SET_SHORTCUT_SORT_ORDER,
  payload: {
    sort: newSortOrder
  }
})

export const openEditShortcut = (shortcutData) => ({
  type: OPEN_EDIT_SHORTCUT,
  payload: shortcutData
})

export const closeEditShortcut = () => ({
  type: CLOSE_EDIT_SHORTCUT,
  payload: null
})


/* Variables */
import {
  ADD_VARIABLE,
  DESTROY_VARIABLE,
  UPDATE_VARIABLE,
} from '../constants/action-types'

export const addVariable = (variableData) => ({
  type: ADD_VARIABLE,
  payload: variableData,
  characterId: store.getState().characterId,
})

export const destroyVariable = (variableData) => ({
  type: DESTROY_VARIABLE,
  payload: variableData
})

export const updateVariable = (variableData) => ({
  type: UPDATE_VARIABLE,
  payload: variableData,
  characterId: store.getState().characterId,
})


/* Misc */
import {
  FILTER_ROLLS,
  SWITCH_PANEL,
} from '../constants/action-types'

export const updateActivityFilter = filterData => ({
  type: FILTER_ROLLS,
  payload: filterData
})

export const switchPanel = (newPanel) => ({
  type: SWITCH_PANEL,
  payload: newPanel
})

/* History */
import {
  ADD_TRACKED_ROLL,
} from '../constants/action-types'

export const addTrackedRoll = rollData => ({
  type: ADD_TRACKED_ROLL,
  payload: rollData
})

/* Initiative Queue Items */
import {
  REORDER_INITIATIVE_QUEUE_ITEMS
} from '../constants/action-types'

export const reorderInitiativeQueueItems = (initiativeQueueItems) => ({
  type: REORDER_INITIATIVE_QUEUE_ITEMS,
  payload: initiativeQueueItems,
})

/* Encounters */
import {
  ADD_ENCOUNTER,
  DESTROY_ENCOUNTER,
  SELECT_ENCOUNTER,
  UPDATE_ENCOUNTER,
  UPDATE_ENCOUNTER_NAME,
  UPDATE_NEW_ENCOUNTER_NAME,
} from '../constants/action-types'

export const addEncounter = encounterData => ({
  type: ADD_ENCOUNTER,
  payload: encounterData,
})

export const updateNewEncounterName = newEncounterName => ({
  type: UPDATE_NEW_ENCOUNTER_NAME,
  payload: {
    newEncounterName: newEncounterName
  }
})

export const selectEncounter = encounterId => ({
  type: SELECT_ENCOUNTER,
  payload: {
    selectedEncounterId: encounterId
  }
})

export const destroyEncounter = encounterData => ({
  type: DESTROY_ENCOUNTER,
  payload: encounterData
})

export const updateEncounterName = updatedEncounterName => ({
  type: UPDATE_ENCOUNTER_NAME,
  payload: updatedEncounterName
})

export const updateEncounter = encounterData => ({
  type: UPDATE_ENCOUNTER,
  payload: encounterData
})


/* Encounter Monsters */
import {
  ADD_ENCOUNTER_MONSTER,
  DESTROY_ENCOUNTER_MONSTER,
  UPDATE_ENCOUNTER_MONSTER,
} from '../constants/action-types'

export const updateEncounterMonster = encounterMonsterData => ({
  type: UPDATE_ENCOUNTER_MONSTER,
  payload: encounterMonsterData
})

export const addEncounterMonster = encounterMonsterData => ({
  type: ADD_ENCOUNTER_MONSTER,
  payload: encounterMonsterData
})

export const destroyEncounterMonster = encounterMonsterData => ({
  type: DESTROY_ENCOUNTER_MONSTER,
  payload: encounterMonsterData
})
